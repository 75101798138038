
import Footer from 'components/Footer';
import Section from "components/Section";
import { usePrefersReducedMotion, useRouteTransition } from 'hooks';

import { useEffect, useRef, useState, Text } from 'react';
import { Helmet } from 'react-helmet';

import '../Home/ProjectSummary.css';
import './confidentiality.css';

const Confidentiality = () => {
  return (
    <div className="project-summary__details">
      <div className="home">
        <Helmet>
          <title>Pick</title>
          <meta
            name="Pick"
            content="Présentation de l'application Pick"
          />
        </Helmet>
        <Section
        >
          <h1>Politique de confidentialité</h1>
          <div>
            <p>Responsable du traitement des données : Sébastien Friedberg</p>
            <h2>1 – Type d’informations :</h2>
            <p>La société recueille et traite, en qualité de responsable du traitement, les données suivantes :</p>
            <ul>
              <li>Identifiants personnels, contacts et caractéristiques (par exemple, nom, prénom, adresse, mail, téléphone, ou toute autre coordonnée…),</li>
              <li>Données de transaction (données nécessaires au paiement bancaire),</li>
              <li>Données techniques (adresse IP...).</li>
            </ul>

            <h2>2 – Obtention des informations :</h2>
            <p>La plupart des données personnelles sont fournies directement par le client pour l'une des raisons suivantes :</p>
            <ul>
              <li>Le client a répondu à un formulaire de contact sur le site internet, et il a ainsi donné son consentement comme base légale de traitement des données. Il peut le retirer à tout moment.</li>
              <li>Le client a sollicité une prestation commerciale, et il a ainsi donné son consentement comme base légale de traitement des données. Il peut le retirer à tout moment. La société a également un intérêt légitime à traiter les informations pour répondre au mieux à la sollicitation du client.</li>
              <li>Le client a effectué un achat sur le site pour son compte. Il s’est ainsi engagé dans une relation contractuelle avec la société, qui utilisera les données communiquées pour exécuter son obligation contractuelle.</li>
              <li>Le client a effectué un achat sur le site pour le compte d’un tiers auquel est livré le produit. Il s’est ainsi engagé dans une relation contractuelle avec la société, qui utilisera les données communiquées pour exécuter son obligation contractuelle.</li>
              <li>Le client a consulté le site internet. La société a ainsi un intérêt légitime à traiter les informations pour améliorer la navigation du client et lui fournir des services adaptés.</li>
              <li>Le client a souscrit à la newsletter, et il a ainsi donné son consentement comme base légale de traitement des données. Il peut le retirer à tout moment.</li>
            </ul>
            <p>La société peut également recevoir indirectement des données personnelles provenant des sources suivantes :</p>
            <ul>
              <li>La mise en relation par des tiers ou partenaires.</li>
            </ul>
          </div>
          <div>
            <h2>3 – Utilisation des données personnelles :</h2>
            <p>Les bases légales pour utiliser les données personnelles sont les suivantes :</p>
            <ul>
              <li>Le client a consenti au traitement de ses données à caractère personnel pour une ou plusieurs finalités spécifiques. Il peut retirer son consentement à tout moment.</li>
              <li>Le traitement est nécessaire à l'exécution d'un contrat auquel le client est partie ou à l'exécution de mesures précontractuelles prises à la demande de celui-ci.</li>
              <li>Le traitement est nécessaire au respect d'une obligation légale à laquelle le responsable du traitement est soumis.</li>
              <li>Le traitement est nécessaire aux fins des intérêts légitimes poursuivis par le responsable du traitement ou par un tiers.</li>
            </ul>
            <p>La société utilise les informations collectées pour les finalités suivantes :</p>
            <ul>
              <li>Fournir les produits et services tels que décrits dans les conditions générales de vente (CGV) le cas échéant et démarrer des relations précontractuelles, sur la base de ses obligations contractuelles.</li>
              <li>Répondre aux demandes d'information et de contact des clients et visiteurs sur le site sur la base de l’intérêt légitime de la société à communiquer avec ses visiteurs et prospects.</li>
              <li>Améliorer et assurer la sécurité du site sur la base de l’intérêt légitime à protéger le site internet d'éventuelles attaques informatiques.</li>
              <li>Réaliser des statistiques sur le site sur la base de l’intérêt légitime à améliorer le site internet et sa visibilité.</li>
              <li>Établir une newsletter d’informations, sur la base légale du consentement du client. Il peut le retirer à tout moment.</li>
            </ul>
            <p>La société peut partager ces informations avec ses prestataires dans le cadre de l'exécution de services. Le site internet est hébergé sur la plateforme XX (voir Mentions légales).</p>
            <p>La société peut également mettre en place une newsletter avec XXX (sous-traitant, partenaire…).</p>
            <p>Dans le cadre de la prestation de service et des actes de vente réalisés sur le site, la société pourra aussi transmettre les données nécessaires au contrat aux salariés et prestataires de l’entreprise pour la préparation des commandes, ainsi qu’aux transporteurs pour la livraison.</p>
            <p>Dans tous les cas, la société met tout en œuvre pour garantir le maximum de protection des données auprès de ses prestataires.</p>
            <p>La société peut être amenée à partager des informations en cas d’obligation légale, ou après une décision judiciaire.</p>
            <p>Les données ne font pas l’objet d’une prise de décision automatisée, de profilage, ni d’utilisation algorithmique.</p>
          </div>
          <div>
            <h2>4 – Stockage des données :</h2>
            <p>Les informations personnelles sont stockées en toute sécurité dans des serveurs situés au sein de l'Union européenne, par l’intermédiaire de la société XX, dont le siège social est basé en France (voir Mentions légales).</p>
            <p>Dans les cas où la société serait amenée à transférer les données en dehors de l'UE/EEE, la société s’assurera d'obtenir les garanties nécessaires pour sécuriser le transfert et assurer le même niveau de protection qu'au sein de l'UE.</p>
            <p>La société conserve les données pendant le temps de la relation contractuelle, ainsi que pour une durée supplémentaire de 5 (cinq) années en archivage intermédiaire pour des raisons de prescription légale.</p>
            <p>La société conserve les données, sauf opposition, pour des fins de prospection, pendant 3 (trois) années à compter du dernier contact émanant de la part du client et de la fin de la relation contractuelle le cas échéant.</p>
            <p>La société conserve les données relatives à la navigation des utilisateurs sur le site internet pendant 13 (treize) mois à compter de leur collecte.</p>
            <p>La société supprime les informations de ses serveurs une fois le laps de temps écoulé.</p>
          </div>
          <div>
            <h2>5 – Exercice des droits sur les données :</h2>
            <h3>Droit d'accès :</h3>
            <p>Le client a le droit de demander à la société l'accès aux données qui le concernent ainsi que d’obtenir une copie de ces données.</p>

            <h3>Droit de rectification :</h3>
            <p>Le client a le droit de demander à la société de rectifier des informations qui le concernent s’il pense qu’elles sont inexactes. Le client a également le droit de demander à la société de compléter les informations que le client pense incomplètes.</p>

            <h3>Droit à l'effacement :</h3>
            <p>Le client a le droit de demander à la société d'effacer les données qui le concernent dans certaines circonstances.</p>

            <h3>Droit à la limitation d'un traitement :</h3>
            <p>Le client a le droit de demander à la société de limiter le traitement d'informations qui le concernent dans certaines circonstances.</p>

            <h3>Droit d’opposition à un traitement :</h3>
            <p>Le client a le droit de s’opposer au traitement de données qui le concernent dans certaines circonstances.</p>

            <h3>Droit à la portabilité des données :</h3>
            <p>Le client a le droit de demander que la société mette à sa disposition l'ensemble des informations qui le concernent pour les transférer vers une autre organisation, dans certaines circonstances.</p>

            <p>Pour exercer leurs droits, les clients peuvent s’adresser à l’adresse postale suivante : rue péligot, France, ou à l’adresse email suivante : sebastien.friedberg@gmail.com. L’exercice des droits est libre et gratuit. La société aura ensuite 1 (un) mois pour répondre à la demande. Un temps supplémentaire pourra toutefois être nécessaire selon la difficulté de la demande.</p>
          </div>
          <div>
            <h2>6 - Réclamation auprès de la CNIL :</h2>
            <p>Si le client estime que ses droits sur ses données ne sont pas respectés, après contact avec la société, il peut adresser une réclamation à la CNIL à l’adresse suivante :</p>
            <p>CNIL - Service des plaintes
              3 Place de Fontenoy
              TSA 80715
              75334 PARIS CEDEX 07
            </p>

            <h2>7 - Cookies et autres traceurs :</h2>
            <p>La société utilise des cookies dans le cadre de la mise à disposition du site internet. Liste des cookies :</p>

            <h2>8 - Date d'entrée en vigueur :</h2>
            <p>Cette version de la politique de gestion des données à caractère personnel est entrée en vigueur le 22/05/2023.</p>
          </div>
        </Section>
        <Footer />
      </div >

    </div >
  );
};

export default Confidentiality;
