export const navLinks = [

  // {
  //   label: 'Pick',
  //   pathname: '/',
  //   hash: '#',
  // }
];

export const socialLinks = [
];
