
import Footer from 'components/Footer';
import Section from "components/Section";
import { usePrefersReducedMotion, useRouteTransition } from 'hooks';

import { useEffect, useRef, useState, Text } from 'react';
import { Helmet } from 'react-helmet';

import '../Home/ProjectSummary.css';
import './eula.css';

const EULA = () => {
  return (
    <div className="project-summary__details">
      <div className="home">
        <Helmet>
          <title>Pick</title>
          <meta
            name="Pick"
            content="Présentation de l'application Pick"
          />
        </Helmet>
        <Section
        >
          <h1>EULA</h1>
          <div>
            <h2>1- ACCEPTATION DE L’EULA</h2>
            <p>
              Sébastien Friedberg est un développeur français (ci-après le « Développeur ») ayant créé une application et un site web dénommés Pick (ci-après « Pick ») et disponible en téléchargement sur l’App Store d’Apple, dont le but est de permettre aux utilisateurs ayant créé un compte (ci-après les « Utilisateurs ») de partager des photos, du texte, d’ajouter ses réseaux sociaux, de commenter les posts des autres Utilisateurs, de voter pour une photo et d’envoyer des messages privés aux Utilisateurs (ci-après les « Contenus »).
            </p>
            <p>
              En utilisant Pick, les Utilisateurs acceptent d’être liés par le présent contrat de licence d’utilisation (ci-après « l’EULA »). L’EULA régit la relation entre le Développeur et les Utilisateurs et prévaut sur tout autre document. Si un Utilisateur n’accepte pas l’EULA, il s’engage à ne pas continuer à utiliser Pick, et à cesser immédiatement tout accès à l’application Pick.
            </p>

            <h2>2- ACCES A PICK</h2>
            <p>
              L’objet de l’EULA est de définir les conditions selon lesquelles :
            </p>
            <ul>
              <li>Le Développeur accepte d’accorder aux Utilisateurs le droit d’accéder à Pick et de l’utiliser,</li>
              <li>Les Utilisateurs peuvent poster des Contenus sur Pick.</li>
            </ul>
            <p>
              L’accès à Pick nécessite une connexion Internet. L’Utilisateur est responsable des moyens et frais techniques et financiers pour accéder à Pick. Le Développeur ne sera pas responsable en cas de difficultés d’accès à Pick.
            </p>
          </div>
          <div>
            <h2>3- COMPTE</h2>
            <p>
              Afin de pouvoir accéder et utiliser les fonctions proposées par Pick, les Utilisateurs doivent créer un compte d’Utilisateur (gratuit). L’enregistrement au service n’est autorisé que pour les personnes morales ou physiques possédant la capacité d’accomplir des actes juridiques aux termes du droit applicable de leur pays de résidence.
            </p>
            <p>
              En créant un compte, les Utilisateurs déclarent :
            </p>
            <ul>
              <li>Avoir au moins 18 ans ou, s’ils ont entre 13 et 18 ans, utiliser Pick en présence d’un parent ou tuteur. Les parents et tuteurs sont solidairement responsables de l’ensemble des actes et omissions de leurs enfants de moins de 18 ans lorsqu’ils utilisent Pick.</li>
              <li>Avoir le droit légal d’accepter l’EULA, y compris le droit de le faire pour le compte de leur organisation.</li>
            </ul>
            <p>
              En créant un compte et en renseignant des informations, les Utilisateurs s’engagent à fournir des informations à jour, exactes et complètes et à les corriger ultérieurement si elles s’avèrent obsolètes ou si elles ne sont plus à jour. En cas de non-respect de cette obligation, le Développeur se réserve le droit de suspendre et/ou de fermer le compte des Utilisateurs et de leur refuser tout accès à Pick.
            </p>
            <p>
              Les Utilisateurs doivent fournir :
            </p>
            <ul>
              <li>un nom d’utilisateur disponible,</li>
              <li>une adresse email et/ou un numéro de téléphone,</li>
              <li>un mot de passe associé au nom d’utilisateur.</li>
            </ul>
            <p>
              Les Utilisateurs s’engagent à ne pas s’enregistrer à l’aide d’un nom d’utilisateur portant atteinte aux droits d’un tiers (notamment un nom d’utilisateur portant atteinte à des droits d’auteur, droits de marque, raisons sociales, noms commerciaux et/ou logos de tiers). Les noms d’Utilisateur et mot de passe sont strictement personnels, et chaque Utilisateur est responsable du respect de leur confidentialité.
            </p>
            <p>
              En cas d’utilisation du nom d’Utilisateur et mot de passe d’un Utilisateur, il est présumé que c’est cet Utilisateur qui accède à Pick. Si un Utilisateur pense que quelqu’un d’autre a accès à son compte, il doit modifier immédiatement son mot de passe et en informer le Développeur par email à l’adresse suivante : <a href="mailto:sebastien.fr95@gmail.com">sebastien.fr95@gmail.com</a>
            </p>
            <p>
              La Politique de confidentialité des données du Développeur est consultable depuis le site : <a href="https://pickofficiel.com">https://pickofficiel.com</a>.
            </p>
          </div>
          <div>
            <h2>4- DESCRIPTION DE PICK</h2>
            <p>Pick permet aux Utilisateurs de :</p>
            <ul>
              <li>de publier des Contenus, en permettant à d’autres Utilisateurs et, plus généralement, à l’ensemble des utilisateurs d’Internet, de les consulter en ligne.</li>
              <li>de partager des Contenus, avec lesquels les Utilisateurs sont susceptibles d’interagir;</li>
              <li>de publier des commentaires/questions sur des Contenus ou sur les Contenus d’autres Utilisateurs publiés en ligne.</li>
              <li>De pouvoir rechercher et regarder les profils des autres utilisateurs.</li>
              <li>De pouvoir voter pour les photos des autres utilisateurs.</li>
              <li>De pouvoir interagir et envoyer des messages aux autres Utilisateurs.</li>
              <li>De pouvoir voir les actions réalisées récemment des autres utilisateurs.</li>
            </ul>
            <p>
              Les Utilisateurs sont expressément informés que Pick, tant qu’il est gratuit, peut inclure des restrictions concernant son utilisation.
            </p>
            <p>
              Il appartient aux Utilisateurs de prendre les mesures nécessaires pour sauvegarder leurs Contenus lorsque la possibilité leur est offerte. Les Contenus seront automatiquement supprimés en cas de dissolution du présent EULA ou de fermeture du compte d’Utilisateur.
            </p>
          </div>
          <div>
            <h2>5- PROPRIETE INTELLECTUELLE DES CONTENUS</h2>
            <p>
              Les Utilisateurs reconnaissent que tout Contenu publié sur Pick relève de leur seule et unique responsabilité. En d’autres termes, les Utilisateurs, et non pas le Développeur, sont responsables de tout le Contenu que les Utilisateurs peuvent être amené à charger, publier, fournir sous licence ou en sous-licence, afficher ou rendre disponible d’une quelconque autre manière via Pick.
            </p>
            <p>
              Pick n’exerce aucun contrôle sur le Contenu publié sur Pick et à ce titre, le Développeur ne peut pas garantir l’exactitude, l’intégrité ou la qualité dudit Contenu ou qu’il n’est pas en infraction avec des législations ou les droits de tiers.
            </p>
            <p>
              Les Utilisateurs demeurent propriétaires des droits de propriété intellectuelle attachés à leur Contenu. La publication du Contenu via Pick n’entraîne aucunement un transfert des droits de propriété intellectuelle au bénéfice du Développeur ou d’un tiers, sauf stipulation contraire de l’EULA.
            </p>
            <p>
              En publiant le Contenu via Pick, les Utilisateurs accordent :
            </p>
            <ul>
              <li>
                au Développeur, une licence mondiale, à titre gratuit, non-exclusive, cessible, transférable et pouvant donner lieu à l’octroi d’une sous-licence, pour la durée de protection des droits de propriété intellectuelle, l’autorisant à utiliser, modifier, adapter, publier, distribuer, reproduire, représenter et exposer les Contenus via Pick, sur tout support et/ou via tout réseau, par tout moyen actuellement connu et/ou inconnu, sous tout format, à des fins de fonctionnement du service, à des fins publicitaires pour promouvoir le site du Développeur et/ou Pick, et à des fins de marketing.
              </li>
              <li>
                à l’ensemble des Utilisateurs, une licence mondiale, à titre gratuit, non-exclusive, non transférable et ne pouvant pas donner lieu à l’octroi d’une sous-licence, pour la durée des droits de propriété intellectuelle, les autorisant à utiliser, reproduire, représenter et exposer le Contenu sur Pick, sur tout autre support et via tout réseau, par tout moyen actuellement connu et inconnu, sous tout format, dans le cadre d’un usage strictement personnel et non commercial.
              </li>
            </ul>
            <p>
              Les Utilisateurs restent exclusivement responsables du Contenu publié ou diffusé via Pick et de sa conformité aux lois et règlements applicables. A ce titre, les Utilisateurs garantissent le Développeur contre tout recours ou toute action résultant de l’exercice des droits accordés au Développeur et/ou aux Utilisateurs par l’EULA.
            </p>
            <p>
              Les liens présents sur Pick et renvoyant à des pages externes sont proposés aux risques et périls des Utilisateurs. Ils ne sont pas gérés par le Développeur, et il ne pourra donc pas être responsable des dommages subis. Les Utilisateurs ne doivent pas insérer de liens hypertextes sans s’être assuré qu’ils sont conformes aux lignes directrices visées ci-dessous ou ne sont pas susceptibles de contrevenir à l’EULA.
            </p>
          </div>
          <div>
            <h2>6- MODERATION DES CONTENUS</h2>
            <p>Les Utilisateurs s’engagent à ne pas :</p>
            <ul>
              <li>Charger, reproduire, publier, afficher, fournir sous licence ou en sous-licence ou rendre autrement disponible des contenus injurieux, inadéquats, illicites, blessants, menaçants, abusifs, de harcèlement, dénaturés, diffamatoires, vulgaires, obscènes, licencieux, intrusifs, haineux ou discutables sur le plan racial, ethnique ou autre, ou contraires à de quelconques lois et réglementations en vigueur ;</li>
              <li>Nuire à des mineurs d’une quelconque façon ;</li>
              <li>Créer des en-têtes ou autrement manipuler les signes d’identification de façon à dissimuler l’origine d’un contenu chargé sur Pick;</li>
              <li>Charger, reproduire, publier, afficher, fournir sous licence ou en sous-licence ou rendre autrement disponible des contenus :
                <ul>
                  <li>que la loi ou une relation contractuelle ou fiduciaire interdit de mettre à disposition,</li>
                  <li>qui contreviennent à des droits sur des brevets, marques, secrets de commerce ou autres droits exclusifs de tiers, ou</li>
                  <li>qui contiennent des virus logiciels ou autre code informatique, fichier ou programme destiné à interrompre, limiter ou détruire la capacité de fonctionnement d’un logiciel ou matériel informatique ou équipement de télécommunication ;</li>
                </ul>
              </li>
              <li>Perturber ou interrompre Pick ou les serveurs ou réseaux connectés à Pick, ou enfreindre toute exigence, procédure, politique ou règlement des réseaux connectés à Pick;</li>
              <li>Modifier, adapter ou pirater Pick ou modifier un autre site de façon à sous-entendre de façon trompeuse qu’il est associé à Pick ;</li>
              <li>Utiliser tout élément disponible sur Pick à moins d’y être expressément autorisé par le Développeur ou en vertu d’une licence distincte ;</li>
              <li>Obtenir ou tenter d’obtenir des matériaux ou informations par des moyens non intentionnellement fournis via Pick ;</li>
              <li>Espionner ou harceler quelqu’un d’une quelconque manière ;</li>
              <li>Créer, envoyer ou soumettre des emails indésirables à un autre Utilisateur de Pick ;</li>
              <li>Collecter ou stocker des données à caractère personnel sur d’autres Utilisateurs de Pick en lien avec la conduite et les activités prohibées susmentionnées; et/ou</li>
              <li>Enfreindre d’une quelconque manière des lois applicables.</li>
            </ul>
            <p>Les Utilisateurs reconnaissent et acceptent que :</p>
            <ul>
              <li>Le Développeur peut supprimer tout Contenu contrevenant à l’EULA et que le Développeur peut accéder, conserver et divulguer des informations relatives à tout compte d’Utilisateur le cas échéant, ainsi que tout Contenu, si la loi l’exige ou s’il est considéré de bonne foi que la conservation ou la divulgation de cet accès est légitimement requis pour :
                <ul>
                  <li>Se conformer à des obligations légales ;</li>
                  <li>Appliquer l’EULA ;</li>
                  <li>Répondre à des réclamations selon lesquelles un contenu enfreint les droits de tiers; ou</li>
                  <li>Protéger les droits, la propriété ou la sécurité personnelle d’une personne.</li>
                </ul>
              </li>
            </ul>
          </div>
          <div>
            <h2>7- LICENCE LIMITEE A L’ACCES ET A L’UTILISATION DE PICK</h2>
            <p>
              Pick, les logiciels en code objet et code source, les scripts, les téléchargements, les graphiques, les photos, les fonctions interactives et autres éléments similaires, ainsi que leur sélection et leur arrangement, les marques, dénominations commerciales, présentations, marques de services, nom d’application et des services, slogans et logos y figurant sont fournis sous licence au Développeur ou sont la propriété exclusive du Développeur et contiennent des informations exclusives et confidentielles.
            </p>
            <p>
              Sous réserve du respect de l’EULA, les Utilisateurs sont uniquement autorisés à accéder à Pick et à l’utiliser à des fins personnelles et non commerciales. Le droit d’accès et d’utilisation accordé est limité, non-exclusif, non transférable et ne peut pas faire l’objet d’une sous-licence, et autorisé uniquement tant que l’EULA reste en vigueur ou pour la durée de validité du compte d’Utilisateur.
            </p>
            <p>
              Tout autre usage est par conséquent expressément prohibé. En particulier, il est interdit, sauf dans les limites autorisées par le droit applicable :
            </p>
            <ul>
              <li>d’accéder à tout ou partie des Contenus et de Pick et/ou de les reproduire, les représenter ou les utiliser de toute autre manière et/ou à toute autre fin que celles qui sont expressément prévues par l’EULA;</li>
              <li>de désassembler, décompiler, étudier à rebours tout ou partie des Contenus et de Pick comprenant un logiciel, ou tenter de découvrir son code source de quelque manière que ce soit. Pick ne peut pas être utilisé avec un autre logiciel ou une autre application, et le Développeur ne fournira pas les informations nécessaires pour exploiter le logiciel accessible via Pick avec un autre logiciel créé de manière indépendante ;</li>
              <li>d’adapter, traduire, modifier tout ou partie des Contenus et de Pick et de créer des produits ou œuvres dérivés de ceux-ci ;</li>
              <li>de diffuser, représenter ou publier tout ou partie des Contenus et de Pick ;</li>
              <li>de louer, vendre, commercialiser, concéder ou transférer tout ou partie des droits des Utilisateurs sur tout ou partie des Contenus et de Pick, ou d’accorder une sous-licence sur lesdits droits, ou d’autoriser la reproduction de tout ou partie des Contenus et de Pick ;</li>
              <li>et, plus généralement, d’accomplir un acte ou d’adopter un comportement non expressément autorisé dans le cadre de l’EULA ou susceptible de violer les droits de propriété intellectuelle du Développeur.</li>
            </ul>
            <p>
              Le droit d’utiliser Pick et l’EULA ne confère aucun droit patrimonial sur tout ou partie des éléments de Pick. Tous les droits qui ne sont pas expressément accordés par l’EULA sont expressément réservés par le Développeur ou des tiers. La structure, l’organisation et le code de Pick constituent des secrets commerciaux et des informations confidentielles précieuses du Développeur et de ses licenciés et fournisseurs.
            </p>
            <p>
              Les Utilisateurs s’engagent en vertu de l’EULA à ne pas retirer et/ou supprimer les mentions relatives au droit d’auteur et à tout autre droit patrimonial figurant sur Pick, ou sur les Contenus.
            </p>
          </div>
          <div>
            <h2>8- ABSENCE D’ASSISTANCE</h2>
            <p>
              Le Développeur n’est pas tenu de fournir une assistance technique, des prestations de maintenance ou toute autre aide dans le cadre de l’utilisation de Pick. En tout état de cause, le Développeur se réserve expressément le droit de corriger toute erreur susceptible d’affecter directement ou indirectement Pick.
            </p>
            <p>
              En cas de modification de Pick, le Développeur déploiera des efforts raisonnables pour ne pas impacter les Contenus ou l’accès/ l’utilisation de Pick.
            </p>

            <h2>9- SUGGESTIONS</h2>
            <p>
              Les Utilisateurs peuvent adresser des suggestions et/ou commentaires concernant Pick en envoyant un email à <a href="mailto:sebastien.fr95@gmail.com">sebastien.fr95@gmail.com</a>. Les Utilisateurs reconnaissent expressément qu’aucune des communications ou publications intervenant dans ce contexte n’est soumise à une quelconque obligation de confidentialité et autorisent le Développeur à réutiliser librement les suggestions et commentaires, sans aucune indemnité.
            </p>
          </div>
          <div>
            <h2>10- RESPONSABILITE</h2>
            <p>
              Pick et les données qu’il contient sont fournis « en l’état » et selon leur disponibilité, à l’exclusion de toute garantie, déclaration, condition ou autre terme de toute nature, qu’ils soient explicites ou implicites, concernant le fonctionnement de Pick ou le contenu informationnel, le matériel ou les produits présents sur Pick.
            </p>
            <p>
              Dans la mesure permise par le droit applicable, le Développeur exclut toute déclaration, garantie, condition et autre terme, incluant, mais sans s’y limiter :
            </p>
            <ul>
              <li>les garanties implicites de qualité marchande, de qualité satisfaisante, d’adéquation à un usage spécifique, de diligence et de compétence ou autre élément similaire partout dans le monde ;</li>
              <li>l’absence d’atteinte aux droits de propriété intellectuelle d’un tiers sur tout contenu publié ou mis à disposition par le biais de Pick ;</li>
              <li>le(s) serveur(s) qui rend(ent) Pick disponible est/sont exempt(s) de virus ou autre code nuisible pouvant infecter, nuire ou causer des dommages à des équipements informatiques ou tout autre bien lorsque les Utilisateurs accèdent à Pick, naviguent sur celui-ci, y effectuent des téléchargements ou l’utilisent d’une quelconque autre manière ;</li>
              <li>l’inexécution des obligations du Développeur du fait de circonstances imprévisibles ou d’un cas de force majeure, tel que défini par le code civil et précisé par la jurisprudence.</li>
              <li>tout inconvénient, obstacle ou dommage inhérent à l’utilisation d’un réseau Internet, en particulier les interruptions de service, les intrusions extérieures ou la présence de virus informatiques, qui seront considérées comme des cas de force majeure.</li>
            </ul>
            <p>
              Dans la mesure permise par le droit applicable, le Développeur décline toute responsabilité pour les frais ou préjudices et dommages qui incombent ou sont occasionnés aux Utilisateurs ou à un tiers, en relation avec Pick ou en lien avec l’utilisation, l’incapacité à utiliser ou les résultats de l’utilisation de Pick, des liens de sites Internet de tiers vers Pick, incluant, mais sans s’y limiter, les dommages et intérêts indirects, punitifs ou consécutifs et toute perte de revenu, bénéfice, clientèle, données, contrats, financement, toute perte ou tout dommage lié d’une quelconque manière à une interruption d’activité, dus à des virus pouvant infecter des équipements informatiques, logiciels, données ou autres biens en raison du fait que les Utilisateurs ont accédé à Pick, ou des sites de tiers ayant un lien vers Pick, que ce soit à titre délictuel (incluant, mais sans s’y limiter, la négligence), contractuel ou autrement.
            </p>
            <p>
              Aucun élément des présentes ne saurait exclure ou limiter la responsabilité du Développeur pour toute responsabilité qui ne peut pas être exclue ou limitée en vertu de la législation en vigueur.
            </p>
            <p>
              L’Utilisateur est responsable des Contenus publiés via Pick et des dommages subis par le Développeur ou par des tiers du fait de ses Contenus ou de son utilisation abusive de Pick. En cas de réclamation d’un tiers à l’encontre du Développeur ou d’un autre Utilisateur, les Utilisateurs s’engagent à défendre le Développeur ou l’autre Utilisateur, à leur propres frais, à dégager le Développeur ou l’autre Utilisateur de toute responsabilité et à lui rembourser toute somme qu’il sera tenu de payer au titre d’une telle réclamation (y compris les honoraires et frais d’avocat).
            </p>
          </div>
          <div>
            <h2>11- RESILIATION</h2>
            <p>
              Les Utilisateurs peuvent mettre fin à l’EULA à tout moment et sans préavis, en fermant leur compte d’Utilisateur. Cette action entrainera la suppression de leurs Contenus.
            </p>
            <p>
              Le Développeur peut fermer des comptes d’Utilisateur, suspendre leur accès à certaines parties de Pick, ou les exclure entièrement s’ils violent l’une quelconque des stipulations de l’EULA suivant la procédure suivante :
            </p>
            <ul>
              <li>
                En cas d’inexécution de l’une des obligations telles que définies par l’EULA, le Développeur adressera une notification par email à l’Utilisateur le mettant en demeure de remédier à une telle violation.
              </li>
              <li>
                Si aucune mesure n’est prise sous huit (8) jours à compter d’une telle notification, ou si l’Utilisateur commet une autre violation au cours de ce délai, le Développeur se réserve le droit de clôturer son compte d’Utilisateur immédiatement.
              </li>
              <li>
                Les manquements particulièrement graves selon l’appréciation du Développeur pourront entrainer la fermeture immédiate du compte d’Utilisateur.
              </li>
            </ul>
            <p>
              L’ensemble des cessions de droits au profit du Développeur, des limitations de garantie et des limitations de responsabilité prévues par l’EULA demeureront en vigueur postérieurement à la cessation de l’utilisation de Pick par un Utilisateur.
            </p>
          </div>
          <div>
            <h2>12- MODIFICATIONS</h2>
            <p>
              Le Développeur peut modifier ou interrompre à tout moment, de manière temporaire ou permanente, tout ou partie de Pick, avec ou sans préavis, sans que sa responsabilité puisse être engagée.
            </p>

            <h2>13- DROIT APPLICABLE – COMPETENCE JURIDICTIONNELLE</h2>
            <p>
              L’EULA sera, à tous égards, régi et interprété en vertu du droit français. Tout litige découlant de l’EULA et de l’utilisation de Pick par les Utilisateurs sera porté devant les tribunaux compétents de Paris. Cette disposition ne fait pas obstacle aux droits des consommateurs ou aux dispositions impératives.
            </p>
          </div>
        </Section>
        <Footer />
      </div >

    </div >
  );
};

export default EULA;
