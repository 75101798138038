import classNames from 'classnames';
import { useId } from 'hooks';
import './index.css';

function Monogram({ highlight, className, ...props }) {
  const id = useId();
  const clipId = `monogram-clip-${id}`;

  return (
    <svg
      aria-hidden
      className={classNames('monogram', className)}
      width="65"
      height="45"
      viewBox="0 0 197.89 373.69"
      {...props}
    >
      <defs>
        <clipPath id={clipId}>
          <path d="M197.89,242.8c-44.41,7.98-125.96,124.43-127.57,130.89c0,0,19.51-61.54,42.07-109.39
	c-32.99-0.47-73.08-16.61-93.12-25.71c-6.77,6.64-14.17,11.33-18.67,6.37C-9.4,233.98,109.09-22.61,165.07,1.61
	c55.98,24.22,9.42,80.74,9.42,80.74c-62.41,20.38-113.26,93.93-131.96,124.11c24.7,14.85,89.53,15.12,94.53,15.12
	c6.36-7.98,12.5-12.74,18.04-12.6C187.4,209.79,197.89,242.8,197.89,242.8z" />

        </clipPath>
      </defs>
      <rect clipPath={`url(#${clipId})`} width="100%" height="100%" />
      {highlight && (
        <g clipPath={`url(#${clipId})`}>
          <rect className="monogram__highlight" width="100%" height="100%" />
        </g>
      )}
    </svg>
  );
}

export default Monogram;
