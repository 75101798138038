import classNames from 'classnames';
import { Button } from 'components/Button';
import { useAppContext, useId } from 'hooks';
import './ThemeToggle.css';

const ThemeToggle = ({ isMobile, ...rest }) => {
  const { dispatch, theme: themeId } = useAppContext();
  const isDark = themeId === 'dark';
  const id = useId();
  const maskId = `theme-toggle-mask-${id}`;

  const handleClick = () => {
    dispatch({ type: 'toggleTheme' });
  };

  return (<></>
  );
};

export default ThemeToggle;
