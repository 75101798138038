import Link from 'components/Link';
import './index.css';
import EULA from 'assets/EULA.pdf';
import Confidentiality from 'assets/Confidentiality.pdf';

const Footer = () => (
  <footer className="footer">
    <div className="footer__pdf-links">
      <a href={'/confidentiality'} className="footer__pdf-link">
        Politique de confidentialité
      </a>
      <span className="footer__pdf-link-separator"> | </span>
      <a href={'/eula'} className="footer__pdf-link">
        EULA
      </a>
    </div>
    <span className="footer__date">
      {`© ${new Date().getFullYear()} Made by Sébastien Friedberg`}
    </span>
    <span className="footer__date">
      {`For any questions, send an email to: sebastien.fr95@gmail.com`}
    </span>
  </footer>
);

export default Footer;
